import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Styled from 'lpnRnVerification_styled'
import { Form, Icon, Popup } from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import {
  postLicenseVerificationDataAction,
  postVerificationFileUploadAction,
} from '../../../../../../../actions/api_actions'
import spinner from '../../../../../../../../../public/assets/img/spinner.gif'
import { containsInvalidCharacters } from './CNAVerification'

const LpnRnVerification = (props) => {
  const [birthYear, birthYearSet,] = useState({
    birthYear: '',
    birthYearMissing: false,
  })
  const [licenseNumber, licenseNumberSet] = useState('')
  const [licenseNumberError, setCredentialNumberError] = useState(null)
  const [ssnNumber, ssnNumberSet] = useState({
    ssnNumber: '',
    ssnNumberMissing: false,
  })
  const [showSpinner, showSpinnerSet,] = useState(false)
  const [fileData, fileDataSet,] = useState({})
  const [translationText,] = useState({
    titleText: `${props.verificationType}TitleText`,
    subtitleText: 'subtitleText',
    licenseNumber: 'licenseNumber',
    licenseNumberPopup: 'licenseNumberPopup',
    licenseNumberTextWarning: 'licenseNumberTextWarning',
    ssnNumber: 'ssnNumber',
    ssnNumberTextWarning: 'ssnNumberTextWarning',
    birthYear: 'birthYear',
    birthYearTextPlaceholder: 'birthYearTextPlaceholder',
    birthYearTextWarning: 'birthYearTextWarning',
    dragTitleText: 'dragTitleText',
    dragTitleOptionalText: 'dragTitleOptionalText',
    dragSubTitleText: 'dragSubTitleText',
    dragFileType: 'dragFileType',
    dragFileSize: 'dragFileSize',
    submitButtonText: 'submitButtonText',
    footerText: 'footerText',
    termsAndConditions: 'termsAndConditions',
  })

  const { t } = props
  const generateYears = () => {
    /* Send back array of years */
    const year = new Date().getFullYear()
    const years = Array.from(new Array(70), (val, index) => year - 16 - index)

    const yearsArray = years.map((item) => ({
      key: item,
      text: item,
      value: item,
    }))

    return yearsArray
  }

  const handleYearChange = (value) => {
    birthYearSet({
      birthYear: value.toString(),
      birthYearMissing: false,
    })
  }

  // licenseNumber, ssnNumber

  const handleLicenseDataChange = (property, value) => {
    /* when there is a change in input reset [property+Missing] to false. This removes warning message that text needs to be entered */

    if (property === 'licenseNumber') {
      licenseNumberSet(value.trim())
      setCredentialNumberError(null)
    }
    if (property === 'ssnNumber') {
      ssnNumberSet({
        ssnNumber: value.trim(),
        ssnNumberMissing: false,
      })
    }
  }

  const handleSubmit = () => {
    /* Before submitting check if all the inputs are filled out and show warning message for the ones that are not */
    let birthYearMissing = true
    let ssnNumberMissing = true
    let licenseNumberErrorSubmit = licenseNumberError

    if (birthYear.birthYear.length > 0) {
      birthYearMissing = false
    }
    if (ssnNumber.ssnNumber.length > 0) {
      ssnNumberMissing = false
    }

    if (!licenseNumber.length) {
      licenseNumberErrorSubmit = t('licenseNumberTextWarning')
      setCredentialNumberError(licenseNumberErrorSubmit)
    } else if (containsInvalidCharacters(licenseNumber)) {
      licenseNumberErrorSubmit = t('licenseNumberNotSupported')
      setCredentialNumberError(licenseNumberErrorSubmit)
    }

    birthYearSet({
      ...birthYear,
      birthYearMissing,
    })

    ssnNumberSet({
      ...ssnNumber,
      ssnNumberMissing,
    })

    if (!birthYearMissing && !licenseNumberErrorSubmit && !ssnNumberMissing) {
      showSpinnerSet(true)

      const rudderstackFields = {
        team_name: props.verificationData.team_name,
        user_type: props.verificationData.user_type,
        team_id: props.verificationData.team_id,
        source: props.verificationData.source,
        parent_id: props.verificationData.parent_id,
        parent_name: props.verificationData.parent_name,
      }

      props
        .postLicenseVerificationDataAction(
          window.activeEnvironment,
          props.verificationData.person_id,
          props.verificationData.state,
          props.verificationData.job_id,
          props.verificationType,
          licenseNumber,
          ssnNumber.ssnNumber,
          birthYear.birthYear,
          rudderstackFields
        )
        .then((response) => {
          showSpinnerSet(false)

          let activeStep = null
          let verificationStatus = null

          if (response && response.active && response.active === 'YES') {
            activeStep = 'verificationStatus'
            verificationStatus = 'success'
          }

          if (response && response.active && response.active === 'NO') {
            activeStep = 'verificationStatus'
            verificationStatus = 'notActive'
          }

          if (
            response &&
            response.active &&
            response.license_status &&
            response.license_status === 'EXPIRED'
          ) {
            activeStep = 'verificationStatus'
            verificationStatus = 'expired'
          }

          /* If file was added, upload it after getting response from verification */
          if (fileData.name) {
            const formFile = new FormData()
            formFile.append('license', fileData)
            formFile.append('person_id', props.verificationData.person_id)
            formFile.append('nurse_id', response.nurse_id)
            formFile.append(
              'uploaded_by_recruiter',
              props.verificationData.uploaded_by_recruiter ?
                props.verificationData.uploaded_by_recruiter :
                0
            )
            formFile.append(
              'rudderstack_fields', JSON.stringify(rudderstackFields)
            )

            props.postVerificationFileUploadAction(
              window.activeEnvironment,
              formFile
            )
          }

          props.changeActiveStep(activeStep, verificationStatus)
        })
        .catch((error) => {
          if (error?.response?.status === 503 || error?.message?.includes('Network Error')) {
            props.changeActiveStep('verificationSuccess', null)
          } else if (error?.response?.status >= 400) {
            props.changeActiveStep('verificationError', error.response.status.toString())
            showSpinnerSet(false)
          } else {
            showSpinnerSet(false)
            props.changeActiveStep('verificationError', error.response.status.toString())
          }
        })
    }
  }

  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0]
    if (
      (file &&
        file.size <= 10000000 &&
        file.type &&
        file.type.match(
          /application\/(pdf|vnd.openxmlformats-officedocument.wordprocessingml.document|msword|jpg)$/
        )) ||
      file.type.match('image/jpeg|image/jpg|image/png')
    ) {
      fileDataSet(file)
    }
  }

  const handleClearFile = () => {
    fileDataSet({})
  }

  return (
    <Styled.LpnRnVerificationContainer data-testid="lpn-rn-verification-form">
      <Styled.TitleText>{t(translationText.titleText)}</Styled.TitleText>
      <Styled.SubTitleText>
        {t(translationText.subtitleText, { state: props.longStateName })}
      </Styled.SubTitleText>
      <Form onSubmit={() => handleSubmit()}>
        <Styled.InputContainer>
          <Styled.InputColumnWrapper>
            {/* License Number */}
            <Styled.FormField>
              <Styled.InputLabel htmlFor="license-number">
                {t(translationText.licenseNumber)}
                <Popup
                  inverted
                  position="top center"
                  content={(
                    <div style={{ 'white-space': 'pre-wrap' }}>
                      {t(translationText.licenseNumberPopup)}
                    </div>
                  )}
                  trigger={<Icon name="question circle outline" />}
                />
              </Styled.InputLabel>

              <Styled.InputField
                type="text"
                id="license-number"
                placeholder="000-000-000"
                value={licenseNumber}
                onChange={(e) => handleLicenseDataChange('licenseNumber', e.target.value)}
              />

              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={!!licenseNumberError}
                unmountOnExit
              >
                <Styled.WarningMessage>{licenseNumberError}</Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>

            {/* SSN Number */}
            <Styled.FormField>
              <Styled.InputLabel htmlFor="ssn-number">
                {t(translationText.ssnNumber)}
              </Styled.InputLabel>
              <Styled.InputField
                id="ssn-number"
                type="text"
                maxLength="4"
                placeholder="0000"
                value={ssnNumber.ssnNumber}
                onChange={(e) => {
                  /* Allow only numbers */
                  const regex = /^[0-9]+$|^$/
                  const matchValue = regex.test(e.target.value)
                  if (matchValue === true) {
                    handleLicenseDataChange('ssnNumber', e.target.value)
                  }
                }}
              />

              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={ssnNumber.ssnNumberMissing}
                unmountOnExit
              >
                <Styled.WarningMessage>
                  {t(translationText.ssnNumberTextWarning)}
                </Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>

            {/* Year dropdown */}
            <Styled.FormField>
              <Styled.InputLabel>
                {t(translationText.birthYear)}
              </Styled.InputLabel>

              <Styled.DropdownField
                data-testid="birth-year-dropdown"
                placeholder={t(translationText.birthYearTextPlaceholder)}
                onChange={(e, { value }) => handleYearChange(value)}
                fluid
                selection
                options={generateYears()}
              />

              <CSSTransition
                timeout={500}
                classNames="warning-message-mount"
                in={birthYear.birthYearMissing}
                unmountOnExit
              >
                <Styled.WarningMessage>
                  {t(translationText.birthYearTextWarning)}
                </Styled.WarningMessage>
              </CSSTransition>
            </Styled.FormField>
          </Styled.InputColumnWrapper>
          <Styled.FileColumnWrapper>
            <Form.Field>
              {/* Dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <Styled.DropzoneField
                      {...getRootProps({ className: 'dropzone' })}
                    >
                      <input
                        {...getInputProps()}
                        accept=".pdf, .docx, .doc, .jpg, .jpeg, .png"
                      />

                      <Styled.FolderIcon name="folder open outline" />
                      <Styled.DragTitle>
                        {t(translationText.dragTitleText)}
                      </Styled.DragTitle>
                      <Styled.DragTitleOptional>
                        {t(translationText.dragTitleOptionalText)}
                      </Styled.DragTitleOptional>
                      <Styled.DragSubTitle>
                        {t(translationText.dragSubTitleText)}
                      </Styled.DragSubTitle>

                      <Styled.DragFileType>
                        {t(translationText.dragFileType)}
                      </Styled.DragFileType>
                      <Styled.DragFileType>
                        {t(translationText.dragFileSize)}
                      </Styled.DragFileType>
                    </Styled.DropzoneField>
                  </section>
                )}
              </Dropzone>

              {/* File Name */}
              <CSSTransition
                timeout={{ enter: 500, exit: 1 }}
                classNames="file-name-mount"
                in={!!fileData.name}
                unmountOnExit
              >
                <Styled.FileNameContainer>
                  <Styled.CheckIconContainer>
                    <Icon name="check" />
                  </Styled.CheckIconContainer>

                  <Styled.FileName>{fileData.name}</Styled.FileName>
                  <Styled.ClearIconContainer onClick={() => handleClearFile()}>
                    <Icon name="times circle outline" />
                  </Styled.ClearIconContainer>
                </Styled.FileNameContainer>
              </CSSTransition>
            </Form.Field>
          </Styled.FileColumnWrapper>
        </Styled.InputContainer>

        {/* Submit button */}
        {showSpinner ? (
          <img src={spinner} width="40" alt="spinner" />
        ) : (
          <Styled.ButtonContainer>
            <Styled.SubmitButton
              data-testid="submit-button"
              type="submit"
              value={t(translationText.submitButtonText)}
            />
          </Styled.ButtonContainer>
        )}
      </Form>

      <Styled.FooterText>
        {t(translationText.footerText)}
        &nbsp;
        <a href="https://www.apploi.com/terms-conditions/" target="_blank" rel="noreferrer">
          {t(translationText.termsAndConditions)}
        </a>
      </Styled.FooterText>
    </Styled.LpnRnVerificationContainer>
  )
}

const mapStateToProps = (state) => ({})

LpnRnVerification.propTypes = {
  changeActiveStep: PropTypes.func, // used to change activeStep which changes the verification screen
  verificationType: PropTypes.string, // lets us know wich verification type we are one (LPN, RN...)
  verificationData: PropTypes.object, // data extracted from URL string
  postLicenseVerificationDataAction: PropTypes.func, // send input data for verification
  postVerificationFileUploadAction: PropTypes.func, // used to upload license files
  longStateName: PropTypes.string, // full name of the state
}

export default connect(mapStateToProps, {
  postLicenseVerificationDataAction,
  postVerificationFileUploadAction,
})(withTranslation('lpnVerification')(LpnRnVerification))
